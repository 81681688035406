import EventEmitter from '@helpers/EventEmitter';
import Fetch from '@helpers/Fetch';
import getAuthPageUrl from '@helpers/getAuthPageUrl';
import isBrowser from '@helpers/isBrowser';
import firebase from 'firebase/app';
import 'firebase/auth';

export enum AuthType {
  App = 'app',
  Web = 'web',
}

export type User = firebase.User;

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: `${process.env.GATSBY_FIREBASE_API_KEY}`,
    appId: `${process.env.GATSBY_FIREBASE_APP_ID}`,
    authDomain: `${process.env.GATSBY_FIREBASE_AUTH_DOMAIN}`,
    measurementId: `${process.env.GATSBY_FIREBASE_MEASUREMENT_ID}`,
    messagingSenderId: `${process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID}`,
    projectId: `${process.env.GATSBY_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}`,
  });
}

export default class AuthClient extends EventEmitter<{
  userAuthenticated: User;
  userUnauthenticated: void;
}> {
  private user?: User | null = null;

  constructor() {
    super();

    if (isBrowser) {
      firebase.auth().onAuthStateChanged(user => {
        if (user && user.emailVerified) {
          this.setCurrentUser(user);
        } else {
          this.unsetCurrentUser();
        }
      });
    }
  }

  public getCurrentUser = (): User | null => {
    return firebase.auth().currentUser ?? null;
  };

  public login = async (username: string, password: string): Promise<User> => {
    const { user } = await firebase.auth().signInWithEmailAndPassword(username, password);

    if (!user) {
      throw new Error('Unable to get user from login response.');
    }

    return user;
  };

  public loginWithGoogle = async (): Promise<User | void> => {
    const { user } = await firebase.auth().getRedirectResult();

    if (!user) {
      const provider = new firebase.auth.GoogleAuthProvider();

      return firebase.auth().signInWithRedirect(provider);
    }

    return user;
  };

  public logout = (): Promise<void> => {
    return firebase.auth().signOut();
  };

  public register = async (
    name: string,
    email: string,
    password: string,
    authType: AuthType
  ): Promise<void> => {
    const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);

    if (!user) {
      throw new Error(`Unable to register user "${email}".`);
    }

    await Promise.all([
      user.updateProfile({ displayName: name }),
      this.sendVerificationEmail(email, authType),
    ]);
  };

  public resendVerificationEmail = (email: string, authType: AuthType): Promise<void> => {
    return this.sendVerificationEmail(email, authType);
  };

  public resetPassword = (code: string, password: string): Promise<void> => {
    return firebase.auth().confirmPasswordReset(code, password);
  };

  public sendResetPasswordEmail = (email: string, authType: AuthType): Promise<void> => {
    const resetPasswordUrl = getAuthPageUrl('resetPassword', authType);
    const baseUrl = `${process.env.GATSBY_BASE_URL}${resetPasswordUrl}`;

    return Fetch.post('/api/public/send-reset-password-email', { baseUrl, email });
  };

  public verifyAccount = async (code: string): Promise<void> => {
    await firebase.auth().applyActionCode(code);

    const user = firebase.auth().currentUser;

    if (user) {
      await user.reload();

      this.setCurrentUser(user);
    }
  };

  private sendVerificationEmail = (email: string, authType: AuthType): Promise<void> => {
    const verifyEmailUrl = getAuthPageUrl('verifyEmail', authType);
    const baseUrl = `${process.env.GATSBY_BASE_URL}${verifyEmailUrl}`;

    return Fetch.post('/api/public/send-verify-email', { baseUrl, email });
  };

  private setCurrentUser = (user: User): User => {
    this.user = user;

    this.emit('userAuthenticated', this.user);

    return this.user;
  };

  private unsetCurrentUser = (): void => {
    this.user = null;

    this.emit('userUnauthenticated', undefined);
  };
}

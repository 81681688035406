// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dashboard-invoices-tsx": () => import("./../../../src/pages/dashboard/invoices.tsx" /* webpackChunkName: "component---src-pages-dashboard-invoices-tsx" */),
  "component---src-pages-dashboard-profile-tsx": () => import("./../../../src/pages/dashboard/profile.tsx" /* webpackChunkName: "component---src-pages-dashboard-profile-tsx" */),
  "component---src-pages-dashboard-subscription-tsx": () => import("./../../../src/pages/dashboard/subscription.tsx" /* webpackChunkName: "component---src-pages-dashboard-subscription-tsx" */),
  "component---src-pages-download-android-tsx": () => import("./../../../src/pages/download/android.tsx" /* webpackChunkName: "component---src-pages-download-android-tsx" */),
  "component---src-pages-download-index-tsx": () => import("./../../../src/pages/download/index.tsx" /* webpackChunkName: "component---src-pages-download-index-tsx" */),
  "component---src-pages-download-ios-tsx": () => import("./../../../src/pages/download/ios.tsx" /* webpackChunkName: "component---src-pages-download-ios-tsx" */),
  "component---src-pages-download-linux-tsx": () => import("./../../../src/pages/download/linux.tsx" /* webpackChunkName: "component---src-pages-download-linux-tsx" */),
  "component---src-pages-download-mac-tsx": () => import("./../../../src/pages/download/mac.tsx" /* webpackChunkName: "component---src-pages-download-mac-tsx" */),
  "component---src-pages-download-windows-tsx": () => import("./../../../src/pages/download/windows.tsx" /* webpackChunkName: "component---src-pages-download-windows-tsx" */),
  "component---src-pages-forgot-password-app-tsx": () => import("./../../../src/pages/forgot-password/app.tsx" /* webpackChunkName: "component---src-pages-forgot-password-app-tsx" */),
  "component---src-pages-forgot-password-index-tsx": () => import("./../../../src/pages/forgot-password/index.tsx" /* webpackChunkName: "component---src-pages-forgot-password-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-app-tsx": () => import("./../../../src/pages/login/app.tsx" /* webpackChunkName: "component---src-pages-login-app-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-oauth-google-app-tsx": () => import("./../../../src/pages/oauth/google/app.tsx" /* webpackChunkName: "component---src-pages-oauth-google-app-tsx" */),
  "component---src-pages-oauth-google-index-tsx": () => import("./../../../src/pages/oauth/google/index.tsx" /* webpackChunkName: "component---src-pages-oauth-google-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-register-app-tsx": () => import("./../../../src/pages/register/app.tsx" /* webpackChunkName: "component---src-pages-register-app-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-resend-verify-email-app-tsx": () => import("./../../../src/pages/resend-verify-email/app.tsx" /* webpackChunkName: "component---src-pages-resend-verify-email-app-tsx" */),
  "component---src-pages-resend-verify-email-index-tsx": () => import("./../../../src/pages/resend-verify-email/index.tsx" /* webpackChunkName: "component---src-pages-resend-verify-email-index-tsx" */),
  "component---src-pages-reset-password-app-tsx": () => import("./../../../src/pages/reset-password/app.tsx" /* webpackChunkName: "component---src-pages-reset-password-app-tsx" */),
  "component---src-pages-reset-password-index-tsx": () => import("./../../../src/pages/reset-password/index.tsx" /* webpackChunkName: "component---src-pages-reset-password-index-tsx" */),
  "component---src-pages-verify-email-app-tsx": () => import("./../../../src/pages/verify-email/app.tsx" /* webpackChunkName: "component---src-pages-verify-email-app-tsx" */),
  "component---src-pages-verify-email-index-tsx": () => import("./../../../src/pages/verify-email/index.tsx" /* webpackChunkName: "component---src-pages-verify-email-index-tsx" */)
}


type Token = string | TokenPromise;

type TokenPromise = () => Promise<string>;

export default class Fetch {
  private token?: Token;

  constructor(token?: Token) {
    if (token) {
      this.token = token;
    }
  }

  static get<TResponse = unknown, TData extends Record<string, string> = never>(
    url: string,
    data?: TData,
    headers?: HeadersInit
  ): Promise<TResponse> {
    let transformedUrl = url;

    if (data) {
      const params = new URLSearchParams(data).toString();

      transformedUrl += `?${params}`;
    }

    return Fetch.makeRequest('GET', transformedUrl, null, headers);
  }

  static post<TResponse = unknown, TData = never>(
    url: string,
    data?: TData,
    headers?: HeadersInit
  ): Promise<TResponse> {
    return Fetch.makeRequest('POST', url, data ? JSON.stringify(data) : null, headers);
  }

  static put<TResponse = unknown, TData = never>(
    url: string,
    data?: TData,
    headers?: HeadersInit
  ): Promise<TResponse> {
    return Fetch.makeRequest('PUT', url, data ? JSON.stringify(data) : null, headers);
  }

  public async get<TResponse = unknown, TData extends Record<string, string> = never>(
    url: string,
    data?: TData
  ): Promise<TResponse> {
    const headers: HeadersInit = {};

    if (this.token) {
      headers.Authorization = await this.getAuthToken();
    }

    return Fetch.get(url, data, headers);
  }

  public async post<TResponse = unknown, TData = never>(
    url: string,
    data?: TData
  ): Promise<TResponse> {
    const headers: HeadersInit = {};

    if (this.token) {
      headers.Authorization = await this.getAuthToken();
    }

    return Fetch.post(url, data, headers);
  }

  public async put<TResponse = unknown, TData = never>(
    url: string,
    data?: TData
  ): Promise<TResponse> {
    const headers: HeadersInit = {};

    if (this.token) {
      headers.Authorization = await this.getAuthToken();
    }

    return Fetch.put(url, data, headers);
  }

  private async getAuthToken(): Promise<string> {
    if (!this.token) {
      throw new Error('A token has not been defined.');
    }

    const token = typeof this.token === 'string' ? this.token : await this.token();

    return `Bearer ${token}`;
  }

  private static async makeRequest<TResponse = unknown>(
    method: string,
    url: string,
    data: string | null = null,
    headers?: HeadersInit
  ): Promise<TResponse> {
    return fetch(url, {
      body: data ?? null,
      headers,
      method,
    })
      .then(data => data.json())
      .then(data => {
        if (data.error === true) {
          console.error(data);

          throw new Error(data.message);
        }

        return data;
      });
  }
}

import { useToasts } from '@context/ToastContext';
import cc from 'classcat';
import React, { FC, useState } from 'react';
import { Notification } from 'react-bulma-components';
import * as styles from './Toasts.module.scss';

const Toasts: FC = () => {
  const { removeToast, toasts } = useToasts();

  const [animatedInToasts, setAnimatedInToasts] = useState<Set<string>>(new Set());

  const handleToastAnimationEnd = (toastId: string) => {
    if (animatedInToasts.has(toastId)) {
      removeToast(toastId);

      setAnimatedInToasts(prevAnimatedInToasts => {
        const updatedAnimatedInToasts = new Set(prevAnimatedInToasts);

        updatedAnimatedInToasts.delete(toastId);

        return updatedAnimatedInToasts;
      });
    }

    setTimeout(() => {
      setAnimatedInToasts(prevAnimatedInToasts => {
        const updatedAnimatedInToasts = new Set(prevAnimatedInToasts);

        updatedAnimatedInToasts.add(toastId);

        return updatedAnimatedInToasts;
      });
    }, 5000);
  };

  return (
    <aside className={styles.toasts}>
      {Array.from(toasts.values()).map(toast => (
        <Notification
          className={cc({
            [styles.toast]: true,
            [styles.fadeOut]: animatedInToasts.has(toast.id),
          })}
          color={toast.type}
          key={toast.id}
          onAnimationEnd={() => handleToastAnimationEnd(toast.id)}
          renderAs="output"
          role="alert"
        >
          {toast.message}
        </Notification>
      ))}
    </aside>
  );
};

export default Toasts;

export const APP_AUTH_PAGE_SUFFIX = '/app';
export const DASHBOARD_INVOICES_PAGE = '/dashboard/invoices';
export const DASHBOARD_PAGE = '/dashboard';
export const DASHBOARD_PROFILE_PAGE = '/dashboard/profile';
export const DASHBOARD_SUBSCRIPTION_PAGE = '/dashboard/subscription';
export const DOWNLOAD_PAGE = '/download';
export const FORGOT_PASSWORD_PAGE = '/forgot-password';
export const GOOGLE_OAUTH_PAGE = '/oauth/google';
export const HOME_PAGE = '/';
export const LOGIN_PAGE = '/login';
export const LOGOUT_PAGE = '/logout';
export const PRICING_PAGE = '/pricing';
export const PRIVACY_POLICY_PAGE = '/privacy';
export const REGISTER_PAGE = '/register';
export const RESEND_VERIFY_EMAIL_PAGE = '/resend-verify-email';
export const RESET_PASSWORD_PAGE = '/reset-password';
export const VERIFY_EMAIL_PAGE = '/verify-email';

export const SUPPORT_EMAIL = 'pomelloapp@gmail.com';

import AuthClient, { User } from '@helpers/AuthClient';
import React, { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';

type LoadingState = AuthStateBase<false>;

type AuthenticatedState = AuthStateBase<true, User>;

type UnauthenticatedState = AuthStateBase<true>;

interface AuthStateBase<TReady extends boolean, TUser extends User | undefined = undefined> {
  authClient: AuthClient;
  isAuthenticated: TUser extends User ? true : false;
  isReady: TReady;
  user: TUser extends User ? TUser : null;
}

type AuthState = LoadingState | UnauthenticatedState | AuthenticatedState;

const AuthContext = createContext<AuthState | undefined>(undefined);

export const useAuth = (): AuthState => {
  const authState = useContext(AuthContext);

  if (!authState) {
    throw new Error('useAuth must be used inside an <AuthContextProvider>');
  }

  return authState;
};

export const AuthContextProvider: FC = ({ children }) => {
  const authClient = useMemo(() => new AuthClient(), []);

  const [authState, setAuthState] = useState<AuthState>({
    authClient,
    isAuthenticated: false,
    isReady: false,
    user: null,
  });

  useEffect(() => {
    const handleUserAuthenticated = (user: User) => {
      setAuthState({
        authClient,
        isAuthenticated: true,
        isReady: true,
        user,
      });
    };

    const handleUserUnauthenticated = () => {
      setAuthState({
        authClient,
        isAuthenticated: false,
        isReady: true,
        user: null,
      });
    };

    authClient.on('userAuthenticated', handleUserAuthenticated);
    authClient.on('userUnauthenticated', handleUserUnauthenticated);

    return () => {
      authClient.off('userAuthenticated', handleUserAuthenticated);
      authClient.off('userUnauthenticated', handleUserUnauthenticated);
    };
  }, [authClient]);

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
};

import React from 'react';
import App from './src/components/App';
import Page from './src/components/Page';

export const wrapRootElement = ({ element }) => {
  return <App>{element}</App>;
};

export const wrapPageElement = ({ element, props }) => {
  return <Page {...props}>{element}</Page>;
};

import { AuthType } from '@helpers/AuthClient';
import * as constants from '../../constants';

type AuthPage =
  | 'forgotPassword'
  | 'googleLogin'
  | 'login'
  | 'register'
  | 'resendVerifyEmail'
  | 'resetPassword'
  | 'verifyEmail';

export default function getAuthPageUrl(page: AuthPage, authType: AuthType): string {
  const pages: Record<AuthPage, string> = {
    forgotPassword: constants.FORGOT_PASSWORD_PAGE,
    googleLogin: constants.GOOGLE_OAUTH_PAGE,
    login: constants.LOGIN_PAGE,
    register: constants.REGISTER_PAGE,
    resendVerifyEmail: constants.RESEND_VERIFY_EMAIL_PAGE,
    resetPassword: constants.RESET_PASSWORD_PAGE,
    verifyEmail: constants.VERIFY_EMAIL_PAGE,
  };

  const pageUrl = pages[page];

  if (authType === AuthType.Web) {
    return pageUrl;
  }

  return `${pageUrl}${constants.APP_AUTH_PAGE_SUFFIX}`;
}

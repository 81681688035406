import { AuthContextProvider } from '@context/AuthContext';
import { ToastContextProvider } from '@context/ToastContext';
import React, { FC } from 'react';
import ReactModal from 'react-modal';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

ReactModal.setAppElement('#___gatsby');

const App: FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <ToastContextProvider>{children}</ToastContextProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
};

export default App;

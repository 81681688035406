import favicon16 from '@assets/images/favicon-16x16.png';
import favicon32 from '@assets/images/favicon-32x32.png';
import Toasts from '@components/Toasts';
import useTranslation from '@helpers/useTranslation';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import './Page.scss';

const Page: FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{t('pomello')}</title>
        <link rel="icon" href={favicon32} sizes="32x32" type="image/png" />
        <link rel="icon" href={favicon16} sizes="16x16" type="image/png" />
      </Helmet>
      <Toasts />
      {children}
    </>
  );
};

export default Page;

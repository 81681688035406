type EventMap = Record<string, unknown>;

type EventType<TEventMap> = string & keyof TEventMap;

type EventHandler<TPayload> = TPayload extends void ? () => void : (payload: TPayload) => void;

type Subscribers = {
  [TType in keyof EventMap]?: EventHandler<EventMap[TType]>[];
};

export default class EventEmitter<TEventMap extends EventMap> {
  private subscribers: Subscribers = {};

  public on = <TType extends EventType<TEventMap>>(
    type: TType,
    handler: EventHandler<TEventMap[TType]>
  ): void => {
    const handlers = this.subscribers[type] ?? [];

    handlers.push(handler);

    this.subscribers[type] = handlers;
  };

  public off = <TType extends EventType<TEventMap>>(
    action: TType,
    handler: EventHandler<TEventMap[TType]>
  ): void => {
    const subscribers = this.subscribers[action];

    if (subscribers) {
      this.subscribers[action] = subscribers.filter(
        subscribedHandler => subscribedHandler !== handler
      );
    }
  };

  protected emit = <TType extends EventType<TEventMap>>(
    type: TType,
    payload: TEventMap[TType]
  ): void => {
    const handlers = this.subscribers[type];

    if (!handlers) {
      return;
    }

    for (const handler of handlers) {
      handler(payload);
    }
  };
}

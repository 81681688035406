import { nanoid } from 'nanoid';
import React, { createContext, FC, useCallback, useContext, useState } from 'react';

interface ToastState {
  createToast: CreateToast;
  removeToast: RemoveToast;
  toasts: Toasts;
}

type CreateToast = (message: string, type?: ToastType) => void;

type RemoveToast = (id: string) => void;

type Toasts = Map<string, Toast>;

type ToastType = 'primary' | 'success' | 'info' | 'warning' | 'danger';

interface Toast {
  id: string;
  message: string;
  type?: ToastType;
}

const ToastContext = createContext<ToastState | undefined>(undefined);

export const useCreateToast = (): CreateToast => {
  const toastState = useContext(ToastContext);

  if (!toastState) {
    throw new Error('useCreateToast must be used inside an <ToastContextProvider>');
  }

  return toastState.createToast;
};

export const useToasts = (): ToastState => {
  const toastState = useContext(ToastContext);

  if (!toastState) {
    throw new Error('useToasts must be used inside an <ToastContextProvider>');
  }

  return toastState;
};

export const ToastContextProvider: FC = ({ children }) => {
  const [toasts, setToasts] = useState<Toasts>(new Map());

  const createToast = useCallback((message: string, type?: ToastType) => {
    setToasts(prevToasts => {
      const id = nanoid();

      const updatedToasts = new Map(prevToasts);

      updatedToasts.set(id, { id, message, type });

      return updatedToasts;
    });
  }, []);

  const removeToast = useCallback((id: string) => {
    setToasts(prevToasts => {
      const updatedToasts = new Map(prevToasts);

      updatedToasts.delete(id);

      return updatedToasts;
    });
  }, []);

  return (
    <ToastContext.Provider value={{ createToast, removeToast, toasts }}>
      {children}
    </ToastContext.Provider>
  );
};
